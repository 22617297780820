import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import SEO from '../components/seo';
import Lightbox from '../components/Lightbox';
import { Page } from '../components/styled';

const Gallery = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const ImageWrapper = styled.div`
    margin: 16px;
    cursor: pointer;
    max-width: 100%;
    overflow: hidden;
`;

const PhotosPage = ({ data }) => {
    const [selectedImage, setSelectedImage] = useState(null);

    return (
        <Page>
            <SEO title="Photos" pathname="/photos" />
            <h1>Photos</h1>
            <Gallery>
                {data.allFile.edges.map(({ node }, index) => (
                    <ImageWrapper
                        onClick={() => setSelectedImage(index)}
                        key={node.childImageSharp.id}
                    >
                        <Img fixed={node.childImageSharp.fixed} />
                    </ImageWrapper>
                ))}
                {selectedImage !== null && (
                    <Lightbox
                        images={data.allFile.edges.map(
                            ({ node }) => node.childImageSharp.fluid
                        )}
                        initialIndex={selectedImage}
                        onExit={() => setSelectedImage(null)}
                    />
                )}
            </Gallery>
        </Page>
    );
};

export const query = graphql`
    query PhotosQuery {
        allFile(filter: { relativeDirectory: { eq: "photos" } }) {
            edges {
                node {
                    childImageSharp {
                        id
                        fixed(height: 300) {
                            ...GatsbyImageSharpFixed
                        }
                        fluid(maxWidth: 1600, quality: 100) {
                            ...GatsbyImageSharpFluid
                            presentationWidth
                        }
                    }
                }
            }
        }
    }
`;

export default PhotosPage;
