import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import AriaModal from '@justfixnyc/react-aria-modal';
import { MdNavigateNext, MdNavigateBefore, MdClose } from 'react-icons/md';
import { Button } from './styled';

const Backdrop = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${(props) =>
        props.entered ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.4)'};
    transform: ${(props) => (props.entered ? 'scale(1)' : 'scale(0.9)')};
    opacity: ${(props) => (props.entered ? '1' : '0.3')};
    transform-origin: center;
    transition: background 200ms, opacity 150ms, transform 150ms;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 50;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
`;

const NonStretchedImage = (props) => {
    let normalizedProps = props;

    if (props.fluid && props.fluid.presentationWidth) {
        normalizedProps = {
            ...props,
            style: {
                ...(props.style || {}),
                maxWidth: props.fluid.presentationWidth,
                maxHeight: '100%',
                position: 'auto',
            },
        };
    }

    return <Img {...normalizedProps} />;
};

const NextButton = styled(Button)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;

    @media (max-width: 680px) {
        position: fixed;
        top: auto;
        bottom: 16px;
        right: auto;
        left: 50%;
        transform: translateX(16px);
    }
`;

const PreviousButton = styled(Button)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;

    @media (max-width: 680px) {
        position: fixed;
        top: auto;
        bottom: 16px;
        left: auto;
        right: 50%;
        transform: translateX(-16px);
    }
`;

export default function Lightbox(props) {
    const [index, setIndex] = useState(props.initialIndex);
    const [modalHasEntered, setModalHasEntered] = useState(false);
    const touchOrigin = useRef({ x: null, y: null });

    const nextImage = useCallback(
        () => setIndex((index + 1) % props.images.length),
        [index, setIndex]
    );

    const previousImage = useCallback(
        () => setIndex((index - 1 + props.images.length) % props.images.length),
        [index, setIndex]
    );

    const closeModal = () => {
        setModalHasEntered(false);
        setTimeout(() => props.onExit(), 200);
    };

    const onTouchStart = (e) => {
        if (e.touches.length > 1) {
            return;
        }

        touchOrigin.current = {
            x: e.touches[0].clientX,
            y: e.touches[0].clientY,
        };
    };

    const onTouchMove = (e) => {
        if (touchOrigin.current.x === null || touchOrigin.current.y === null) {
            return;
        }

        const diffX = e.touches[0].clientX - touchOrigin.current.x;
        const diffY = e.touches[0].clientY - touchOrigin.current.y;

        if (diffX * diffX + diffY * diffY < 400) {
            return;
        }

        if (Math.abs(diffY) > Math.abs(diffX)) {
            touchOrigin.current = {
                x: null,
                y: null,
            };

            return;
        }

        if (diffX > 20) {
            touchOrigin.current = {
                x: null,
                y: null,
            };

            previousImage();
        }

        if (diffX < -20) {
            touchOrigin.current = {
                x: null,
                y: null,
            };

            nextImage();
        }
    };

    useEffect(() => {
        function keyboardListener({ key }) {
            if (key === 'ArrowRight') {
                nextImage();
            }

            if (key === 'ArrowLeft') {
                previousImage();
            }
        }

        window.addEventListener('keydown', keyboardListener);

        return () => window.removeEventListener('keydown', keyboardListener);
    }, [nextImage, previousImage]);

    return (
        <AriaModal
            onExit={closeModal}
            onEnter={() => setModalHasEntered(true)}
            underlayColor="rgba(0, 0, 0, 0.1)"
            titleText="Photos"
        >
            <Backdrop
                entered={modalHasEntered}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
            >
                <NonStretchedImage
                    fluid={props.images[(index + 1) % props.images.length]}
                    imgStyle={{ objectFit: 'contain' }}
                    objectFit="contain"
                    style={{
                        opacity: '0',
                    }}
                    aria-hidden="true"
                />
                <NonStretchedImage
                    fluid={
                        props.images[
                            (index - 1 + props.images.length) %
                                props.images.length
                        ]
                    }
                    imgStyle={{ objectFit: 'contain' }}
                    objectFit="contain"
                    style={{
                        opacity: '0',
                    }}
                    aria-hidden="true"
                />
                <NonStretchedImage
                    fluid={props.images[index]}
                    imgStyle={{ objectFit: 'contain' }}
                    objectFit="contain"
                />
                <NextButton onClick={nextImage} aria-label="Next photo">
                    <MdNavigateNext size="48px" color="white" />
                </NextButton>
                <PreviousButton
                    onClick={previousImage}
                    aria-label="Previous photo"
                >
                    <MdNavigateBefore size="48px" color="white" />
                </PreviousButton>
                <Button
                    style={{
                        position: 'absolute',
                        right: '16px',
                        top: '16px',
                    }}
                    onClick={closeModal}
                    aria-label="Close photo gallery"
                >
                    <MdClose size="24px" color="white" />
                </Button>
            </Backdrop>
        </AriaModal>
    );
}
